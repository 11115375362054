<template>
  <div>
    <v-container class="pb-0">
      <h1 class="my-8">{{ $t("Billing") }}</h1>

      <v-divider class="mb-12" />
      <v-card elevation="4">
        <v-card-text>
          <v-row>
            <v-col>
              <h3>Billing Details</h3>
              <v-progress-linear v-if="loading" indeterminate />
              <span v-else-if="noBillingAccount">
                {{ $t("Billing account will be created with first payment") }}
              </span>
              <table v-else>
                <tr>
                  <td><strong>Company:</strong></td>
                  <td>{{ details.companyname }}</td>
                </tr>
                <tr>
                  <td><strong>Name:</strong></td>
                  <td>{{ details.firstname }} {{ details.lastname }}</td>
                </tr>
                <tr>
                  <td><strong>Address:</strong></td>
                  <td>{{ details.address1 }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {{ details.postcode }}, {{ details.city }},
                    {{ details.state }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>{{ details.country }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-divider class="mb-12" />

      <v-card elevation="4">
        <v-card-text class="pa-0">
          <v-row>
            <v-col>
              <InvoicesTable :loading="loading" :invoices="invoices" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import InvoicesTable from '../components/dataIterator/tables/InvoicesTable.vue';

export default {
  components: {
    InvoicesTable,
  },
  mounted() {
    this.loadDetails();
  },
  data() {
    return {
      loading: false,
      noBillingAccount: false,
      details: {
        firstname: "",
        lastname: "",
        companyname: "",
        address1: "",
        postcode: "",
        city: "",
        state: "",
        country: "",
        services: [],
      },
    };
  },
  computed: {
    invoices() {
      if (this.details === null) {
        return [];
      }
      let invoices = [];
      for (let service of this.details.services) {
        for (let invoice of service.invoices) {
          invoices.push(invoice);
        }
      }
      return invoices;
    },
  },
  methods: {
    loadDetails() {
      this.loading = true;
      Api.get("/billing")
        .then((response) => {
          if (response.data.data === null) {
            this.noBillingAccount = true;
            return;
          }
          this.details = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
