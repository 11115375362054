<template>
  <v-card elevation="4">
    <v-card-text>
      <div class="row">
        <div class="col">
          <h3>Invoices</h3>
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="invoices"
            hide-default-footer
            class="elevation-0 custom-data-table"
            :loading="loading"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    invoices: Array,
    loading: Boolean,
  },
  data() {
    return {
      itemsLoading: false,
      headers: [
        {
          text: this.$t("Date"),
          value: "date",
          sortable: true,
        },
        {
          text: this.$t("Number"),
          value: "id",
          sortable: true,
        },
        {
          text: this.$t("Amount"),
          value: "total",
          sortable: true,
        },
        {
          text: this.$t("Status"),
          value: "status",
          sortable: true,
        },
        {
          text: this.$t("table.header.actions"),
          value: "actions",
        },
      ],
      items: [],
    };
  },
};
</script>
